/**
 * Listener on window custom state change
 * @param {object} options Desktop state
 * @param {object} options.self The window Object
 * @param {boolean} options.value The custom state value
 */
akioma.eventEmitter.on(akioma.swat.GlobalHooks.WINDOW.CUSTOM_STATE_CHANGED, ({ self, value }) => {
  // Change to action
  if (akioma.dataViewTabs) {
    // contains dhtmlx dataview taskbar
    const item = akioma.dataViewTabs.get(self.controller.opt.id);
    if (item) {
      item.cssCustomState = value;
      akioma.dataViewTabs.update(self.controller.opt.id, item);
    }
  }
});

/**
 * Event Listener for adding new taskbar items when a new container has been launched
 * @param {ak_window|ak_frame} containerControl Window/Frame container launched
 */
akioma.eventEmitter.on(akioma.swat.GlobalHooks.LAUNCH.AFTER_LOAD, containerControl => {
  const primaryDataSource = containerControl.dynObject.getLink('PRIMARYSDO:TARGET');
  const isLoginScreen = app.sessionData.loginScreen === containerControl.controller.opt.name;

  if (isNull(akioma.dataViewTabs) || containerControl.controller.bMainWindow || containerControl.controller.opt.modal || isLoginScreen)
    return;
  if (containerControl.controller.view !== 'window')
    return;

  /**
   * Method for seting current window title in taskbar
   * @return  {void}
   */
  const setActiveTaskbarItem = () => {
    // need to use timeout after add because of delayed rendering in dhtmlx component.. _render_delayed
    setTimeout(() => {
      akioma.dataViewTabs.unselectAll();
      akioma.iActiveDataViewIndex = akioma.dataViewTabs.indexById(containerControl.controller.opt.id);
      akioma.dataViewTabs.show(containerControl.controller.opt.id);
      if (akioma.makeActiveOpenedWindow)
        akioma.dataViewTabs.select(containerControl.controller.opt.id, true);
    }, 50);
  };

  if (primaryDataSource) {
    const primaryDataSourceControl = primaryDataSource.controller;
    // after fill add taskbar item and group
    primaryDataSourceControl.addAfterFillOnceCallback(() => {
      const cTitleTemplateCompiled = primaryDataSourceControl.getRecordDescription(containerControl.controller.opt.TITLE);
      const cTitleShortTemplateCompiled = primaryDataSourceControl.getRecordDescription(containerControl.controller.opt.titleShort);
      akioma.dataViewTabs.add({
        id: containerControl.controller.opt.id,
        label: (cTitleShortTemplateCompiled || cTitleTemplateCompiled),
        Name: containerControl.controller.opt.title,
        icon: containerControl.controller.opt.icon,
        cssText: false,
        oBE: primaryDataSource
      });
      setActiveTaskbarItem();
    });
  } else {
    akioma.dataViewTabs.add({
      id: containerControl.controller.opt.id,
      label: (containerControl.controller.opt.titleShort || containerControl.controller.opt.TITLE),
      Name: containerControl.controller.opt.title,
      icon: containerControl.controller.opt.icon,
      cssText: false
    });
    setActiveTaskbarItem();
  }
});


/**
 * Event listener called on an after save changes on a dataSource/businessEntity,
 * used for updating the group title after a dataSource saveChanges operation
 * @param {ak_businessEntity} dataSource
 */
akioma.eventEmitter.on(akioma.swat.GlobalHooks.DATASOURCE.AFTER_SAVE_CHANGES, async dataSource => {
  let containerControl;
  let primaryDataSource;

  if (dataSource.container)
    containerControl = dataSource.container.controller;
  if (containerControl && containerControl.dynObject)
    primaryDataSource = containerControl.dynObject.getLink('PRIMARYSDO:TARGET');

  if (isNull(akioma.dataViewTabs) || containerControl?.view !== 'window' || !primaryDataSource)
    return;

  /**
   * Set taskbar title
   * @param {Object} BE
   * @param {string} cTemplate
   * @param {string} cShortTemplate
   * @returns {void}
   */
  const setTaskbarTitle = params => {
    const { BE, cShortTemplate } = params;

    if (BE && BE.controller) {
      const cTitleShortTemplateCompiled = BE.controller.getRecordDescription(cShortTemplate);
      const item = akioma.dataViewTabs.get(containerControl.opt.id);
      if (item) {
        // template changes to display correct akhasChanges and akHasErrors flag in dhtmlx_debug.js
        item.cssText = BE.container.controller.oVuexState.hasChanges;
        item.cssError = BE.container.controller.oVuexState.hasErrors;
        item.cssCustomState = BE.container.controller.oVuexState.customStates;
        item.label = cTitleShortTemplateCompiled;
        item.title = cTitleShortTemplateCompiled;
        akioma.dataViewTabs.update(containerControl.opt.id, item);
      }
    }
  };

  const cShortTemplate = containerControl.opt.titleShort || containerControl.opt.TITLE;
  const cTemplate = containerControl.getTitleTemplate();
  setTaskbarTitle({ BE: primaryDataSource, cTemplate, cShortTemplate });
});

/**
 * Event listener called on a window has errors state change, used for setting the taskbar has errors state
 * @param {akioma.swat.Window} windowControl
 * @param {boolean} hasErrors
 */
akioma.swat.GlobalEmitter.on(akioma.swat.GlobalHooks.WINDOW.HAS_ERRORS, ({ windowControl, hasErrors }) => {
  if (!akioma.dataViewTabs)
    return;

  // dhtmlx dataview taskbar
  const item = akioma.dataViewTabs.get(windowControl.controller.opt.id);
  if (item) {
    item.cssError = hasErrors;
    akioma.dataViewTabs.update(windowControl.controller.opt.id, item);
  }
});

/**
 * Event listener called on a window has changes state change, used for setting the taskbar has changes state
 * @param windowControl
 * @param hasErrors
 */
akioma.swat.GlobalEmitter.on(akioma.swat.GlobalHooks.WINDOW.HAS_CHANGES, ({ windowControl, hasChanges }) => {
  if (!akioma.dataViewTabs)
    return;

  const item = akioma.dataViewTabs.get(windowControl.controller.opt.id);
  if (item) {
    item.cssText = hasChanges;
    item.cssErrors = windowControl.controller.oVuexState.attributes.hasErrors;

    akioma.dataViewTabs.update(windowControl.controller.opt.id, item);
  }
});

/**
 * Event listener called on a window has changes state change, used for setting the taskbar has changes state
 * @param windowControl
 * @param hasErrors
 */
akioma.swat.GlobalEmitter.on(akioma.swat.GlobalHooks.DESIGNER.AFTER_LOAD, ({ windowControl, title, subtitle }) => {
  if (!akioma.dataViewTabs)
    return;

  const containerId = windowControl.controller.opt.id;

  title += ` ${subtitle}`;
  const containerTab = akioma.dataViewTabs.get(containerId);
  if (!isNull(containerTab)) {
    containerTab.title = title;
    containerTab.label = title;
    akioma.dataViewTabs.update(containerId, containerTab);
  }
});
